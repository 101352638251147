body {
	font-family: $font-family-body;
}

.sgj-icon-menu {
	margin-left: 10px;
	font-size: 3.2em;
}

.bg-gradient {
	 background-image: linear-gradient(to right, $secondary, $primary);
	}

h1, h2, h3, h5, .navbar {
	font-family: $headings-font-family;
	color: $primary;
}

h4 {
	font-family: $headings-font-family;
	color: white;
	font-size: 2.4em; 
}

p {
	color: $gray-600;
}

		// Example
		// Apply styles starting from medium devices and up to extra large devices
		@include media-breakpoint-up(xl) {  
		.designservices p {
			font-size: 0.85em;
			line-height: 1.6em;
		}
		}



.navbar li {
	font-size: 1.4em;
}

	@media (min-width:1100px) {
		.navbar li {
		font-size: 2.0em;
		margin-left: 20px;
		}
	}

.bg-squares-right {
	/* Set a specific height */
    height: 400px; 

    /* The image used */
    background-image: url("https://sgj-designstudio.com/assets/header-squares-right.jpg");
    background-repeat: no-repeat;
  	background-position: right;
}

.bg-squares-left {
	/* Set a specific height */
    height: 400px; 

    /* The image used */
    background-image: url("https://sgj-designstudio.com/assets/header-squares-left.jpg");
    background-repeat: no-repeat;
  	background-position: left;
}

.bg-display-squares {
	/* Set a specific height */
    height: 250px; 

    /* The image used */
    background-image: url("https://sgj-designstudio.com/assets/display-squares.jpg");
    background-repeat: no-repeat;
  	background-position: left;
}


.HeroContainer {
	position: relative;
}

.overthetop {
	width: 60%;
	position: absolute;
	transition: .5s ease;
	left: 2%;
	bottom: 18%;

}
// SM:
@include media-breakpoint-up(sm) {
	
	.overthetop {
		width: 70%;
		left: 4%;
		bottom: 6%;
	}

}


// lg:
@include media-breakpoint-up(lg) {
	
	.overthetop {
		width: 70%;
		left: 5%;
	}

} 


// XL:
@include media-breakpoint-up(xl) {
	
	.overthetop {
		width: 40%;
		left: 25%;
	}

}



.bg-gray {
	background-color: $gray-200;
	padding: 60px 40px;
}


.bg-blue {
	 background-image: linear-gradient(to bottom right, $secondary-light, $primary-light);
}

.bg-blue h2, .bg-blue p {
	 color: white;
}

ul.footer-nav   {
	list-style-type: none;
	font-family: $headings-font-family;
	font-size: 1.4em;
	line-height: 1.2em;
}

