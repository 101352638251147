
$darkblue: 		#003750;
$lightblue:		#4f7494;

$primary:       $darkblue;
$dark: 		    $darkblue;
$secondary:     $lightblue;

$primary-light		: 		lighten($primary, 5%);
$primary-dark		: 		darken($primary, 25%);

$secondary-light	: 		lighten($secondary, 5%);
$secondary-dark		: 		darken($secondary, 25%);


$body-bg:       white;


//== Social Colors

$facebook			:		#3b5998;
$facebook-dark		: 		darken($facebook, 25%);
$twitter			:		#1DA1F2;
$twitter-dark		: 		darken($twitter, 25%);
$google				:		#DB4437;
$google-dark		: 		darken($google, 25%);
$instagram			:		#262626;
$instagram-dark		: 		darken($instagram, 25%);
$linkedin			:		#0077B5;
$linkedin-dark		: 		darken($linkedin, 25%);
$pinterest			:		#BD081C;
$pinterest-dark		: 		darken($pinterest, 25%);
$youtube			:		#282828;
$youtube-dark		: 		darken($pinterest, 25%);

$social-btn-padding-y:         .2rem;
$social-btn-padding-x:         .5rem;
$social-btn-line-height:       .9rem;





