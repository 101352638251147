// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks



// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css?family=Hind|Teko:700';

$font-family-body		:		'Hind', sans-serif;
$headings-font-family  	:		'Teko', sans-serif;
 
.fancy {
	font-family: $headings-font-family;
	font-size: 1.9em;
}
 
.display-1, .display-2, .display-3, .display-4, .btn {
	font-family: $headings-font-family;
}

.hero-text {
	font-family: $headings-font-family;
	font-size: 1.4em;
	line-height: 1.1em;
}

.display-home {
	font-family: $headings-font-family;
	font-size: 3.2em;
	line-height: 1em;
}


// SM:
@include media-breakpoint-up(sm) {
	.hero-text {
	font-size: 2.2em;
	}

}

// SM:
@include media-breakpoint-up(lg) {
	.hero-text {
	font-size: 3.2em;
	}

}

// XL:
@include media-breakpoint-up(xl) {
	.display-home {
		font-size: 4.6em;
	}

	.hero-text {
	}

}
